import React, { useEffect, useRef, useState } from "react";
import { Network, DataSet } from "vis-network/standalone/esm/vis-network";

const NetworkGraph = ({ nodesData, edgesData }) => {
  const graphRef = useRef(null);
  const networkInstance = useRef(null);
  const nodes = useRef(new DataSet());
  const edges = useRef(new DataSet());

  const [selectValue, setSelectValue] = useState([
    "Select By Id",
    "Select By Group",
  ]);

  var highlightActive = false;

  // base network configuration
  let options = {
    nodes: {
      shape: "dot",
      fixed: {
        x: false,
        y: false,
      },
      size: 10,
    },
    edges: {
      arrows: {
        to: {
          enabled: true,
          scaleFactor: 0.5,
          type: "arrow",
        },
      },
      smooth: false,
      width: 1.5,
      color: {
        opacity: 0.5,
      },
    },
    physics: false,
  };

  // get all group and node options for the dropdown selectors
  let groups = nodesData
    .map((value) => value.group)
    .filter((value, index, _arr) => _arr.indexOf(value) === index)
    .sort();
  let ids = nodesData
    .filter((value) => value.label !== undefined)
    .map((value) => ({
      id: value.id,
      label: value.label,
    }));

  // Function to highlight nodes by group
  const highlightGroup = (groupName) => {
    const updatedNodes = nodesData.map((node) => {
      if (node.group === groupName) {
        return {
          ...node,
          size: 20, // Increase size for highlighted nodes
        };
      } else {
        return {
          ...node,
          color: { background: "lightgray", border: "gray" }, // Dimmed color
          size: 10,
        };
      }
    });

    nodes.current.update(updatedNodes);
  };

  // Function to reset all node styles
  const resetHighlight = () => {
    const allNodes = nodes.current.get();
    const resetNodes = allNodes.map((node) => ({
      ...node,
      size: 10, // Reset size
      color: undefined, // Use default group color
    }));

    nodes.current.update(resetNodes);
  };
  // Debounced update flag
  const updateTimeout = useRef(null);

  const handleGroupChange = (event) => {
    event.preventDefault();
    let newState = ["Select By Id", event.target.value];
    setSelectValue(newState);
    if (event.target.value === "Select By Group") {
      resetHighlight();
    } else {
      highlightGroup(event.target.value);
    }
  };

  const handleIdChange = (event) => {
    event.preventDefault();
    let newState = [parseInt(event.target.value), "Select By Group"];
    setSelectValue(newState);
    if (event.target.value === "Select By Id") {
      resetHighlight();
    } else {
      const params = { nodes: [event.target.value] };
      neighbourhoodHighlight(params);
    }
  };

  function neighbourhoodHighlight(params) {
    // if something is selected:
    const allNodes = nodes.current.get({ returnType: "Object" });
    setSelectValue([params.nodes[0], "Select By Group"]);
    if (params.nodes.length > 0) {
      highlightActive = true;
      let i, j;
      let selectedNode = params.nodes[0];
      let degrees = 2;

      // mark all nodes as hard to read.
      for (let nodeId in allNodes) {
        allNodes[nodeId].colorOriginal = allNodes[nodeId].color;
        allNodes[nodeId].color = "rgba(200,200,200,0.5)";
        if (allNodes[nodeId].hiddenLabel === undefined) {
          allNodes[nodeId].hiddenLabel = allNodes[nodeId].label;
          allNodes[nodeId].label = undefined;
        }
      }
      let connectedNodes =
        networkInstance.current.getConnectedNodes(selectedNode);
      let allConnectedNodes = [];

      // get the second degree nodes
      for (i = 1; i < degrees; i++) {
        for (j = 0; j < connectedNodes.length; j++) {
          allConnectedNodes = allConnectedNodes.concat(
            networkInstance.current.getConnectedNodes(connectedNodes[j])
          );
        }
      }

      // all second degree nodes get a different color and their label back
      for (i = 0; i < allConnectedNodes.length; i++) {
        allNodes[allConnectedNodes[i]].color =
          allNodes[allConnectedNodes[i]].colorOriginal;
        if (allNodes[allConnectedNodes[i]].hiddenLabel !== undefined) {
          allNodes[allConnectedNodes[i]].label =
            allNodes[allConnectedNodes[i]].hiddenLabel;
          allNodes[allConnectedNodes[i]].hiddenLabel = undefined;
        }
      }

      // all first degree nodes get their own color and their label back
      for (i = 0; i < connectedNodes.length; i++) {
        allNodes[connectedNodes[i]].color = undefined;
        if (allNodes[connectedNodes[i]].hiddenLabel !== undefined) {
          allNodes[connectedNodes[i]].label =
            allNodes[connectedNodes[i]].hiddenLabel;
          allNodes[connectedNodes[i]].hiddenLabel = undefined;
        }
      }

      // the main node gets its own color and its label back.
      allNodes[selectedNode].color = undefined;
      if (allNodes[selectedNode].hiddenLabel !== undefined) {
        allNodes[selectedNode].label = allNodes[selectedNode].hiddenLabel;
        allNodes[selectedNode].size = 70;
        allNodes[selectedNode].hiddenLabel = undefined;
      }
    } else if (highlightActive === true) {
      // reset all nodes
      for (var nodeId in allNodes) {
        allNodes[nodeId].color = undefined;
        if (allNodes[nodeId].hiddenLabel !== undefined) {
          allNodes[nodeId].label = allNodes[nodeId].hiddenLabel;
          allNodes[nodeId].hiddenLabel = undefined;
        }
      }
      highlightActive = false;
    }

    // transform the object into an array
    var updateArray = [];
    for (nodeId in allNodes) {
      if (allNodes.hasOwnProperty(nodeId)) {
        updateArray.push(allNodes[nodeId]);
      }
    }

    nodes.current.update(updateArray);
    //setData([nodesDataset, edgesDataset])
  }

  useEffect(() => {
    if (!graphRef.current) return;

    // Initialize the network once
    if (!networkInstance.current) {
      nodes.current = new DataSet(nodesData);
      edges.current = new DataSet(edgesData);

      networkInstance.current = new Network(
        graphRef.current,
        { nodes: nodes.current, edges: edges.current },
        options
      );
    }

    return () => {
      if (networkInstance.current) {
        networkInstance.current.destroy();
        networkInstance.current = null;
      }
    };
  }, [nodesData, edgesData]); // Run only on mount

  useEffect(() => {
    resetHighlight();
    if (!networkInstance.current) return;

    // timeout function fo give the graph just a few ms to redraw the update for nodes and edges
    if (updateTimeout.current) clearTimeout(updateTimeout.current);

    updateTimeout.current = setTimeout(() => {
      const currentView = networkInstance.current.getViewPosition();
      const currentScale = networkInstance.current.getScale();

      nodes.current.update(nodesData);
      edges.current.update(edgesData);

      // Restore zoom and position
      networkInstance.current.moveTo({
        position: currentView,
        scale: currentScale,
        animation: false,
      });

      // Use `network` here to configure events, etc
      networkInstance.current.on("click", neighbourhoodHighlight);
    }, 100); // 100ms debounce
  }, [nodesData, edgesData]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 mt-3">
            <select
              style={{ width: 200 }}
              value={selectValue[0]}
              defaultValue={"Select By Id"}
              onChange={(event) => handleIdChange(event)}
              className="form-control"
              id="opt1"
            >
              <option value={"Select By Id"}>{"Select By Id"}</option>
              {ids.map((option) => (
                <option value={option.id}>{option.label}</option>
              ))}
            </select>
            <br />
            <select
              style={{ width: 200 }}
              value={selectValue[1]}
              defaultValue={"Select By Id"}
              onChange={(event) => handleGroupChange(event)}
              className="form-control"
              id="opt1"
            >
              <option value={"Select By Group"}>{"Select By Group"}</option>
              {groups.map((option) => (
                <option value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="col-lg-9 mt-3 box-network">
            <div
              ref={graphRef}
              className="row mt-3 justify-content-md-center "
              style={{ height: 600 }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NetworkGraph;
