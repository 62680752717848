import { useSelector } from "react-redux";
import { SelectCurrentProject } from "../../features/projects/projectSlice";
import { useState, useEffect } from "react";
import { useGetChangeAgentsQuery } from "../../features/report/changeAgentsApiSlice";
import { useGetNetworkRelationsQuery } from "../../features/report/networkRelationsApiSlice";
import Loader from "../Loader";
import NetworkGraphBasic from "../graph/NetworkGraphBasic";
import { DataTable } from "../data-table/DataTable";

const ChangeAgents = () => {
  const selectedProject = useSelector(SelectCurrentProject);
  const {
    data: changeAgentsData,
    isLoading: isChangeAgentsLoading,
    isSuccess: isChangeAgentsSuccess,
    isError: isChangeAgentsError,
    error: changeAgentsError,
  } = useGetChangeAgentsQuery(selectedProject);

  // Fetch Network Relations data
  const {
    data: networkData,
    isLoading: isNetworkLoading,
    isSuccess: isNetworkSuccess,
  } = useGetNetworkRelationsQuery(selectedProject);

  // Combined loading and error states
  const isLoading = isChangeAgentsLoading || isNetworkLoading;
  const isSuccess = isChangeAgentsSuccess && isNetworkSuccess;
  const isError = isChangeAgentsError;
  const error = changeAgentsError;

  const [selectedPeople, setSelectedPeople] = useState(null);

  const COLUMNS = [
    {
      Header: "Ranking",
      editable: false,
      accessor: "rank",
      disableFilters: true,
      Cell: ({ value }) => Number(value),
    },
    {
      Header: "Name",
      accessor: "label",
      editable: false,
    },
    {
      Header: "Impact score",
      accessor: "y",
      editable: false,
    },
  ];

  let input = [[], []];

  useEffect(() => {
    if (changeAgentsData) {
      setSelectedPeople(Math.ceil(changeAgentsData.length * 0.2));
    }
  }, [changeAgentsData]);

  function getScatterData() {
    input[1] = changeAgentsData.slice(0, selectedPeople);
    input[0] = changeAgentsData.slice(selectedPeople, changeAgentsData.length);
  }

  function labelNetworkData() {
    let labelNetwork = [[], []];
    const labelMap = new Map(
      changeAgentsData.map((label) => [label.uid, label.label])
    );
    const nodeSizeMap = new Map(
      changeAgentsData.map((nodeSize) => [nodeSize.uid, nodeSize.y])
    );
    labelNetwork[0] = JSON.parse(JSON.stringify([...networkData.Nodes]));
    labelNetwork[1] = JSON.parse(JSON.stringify([...networkData.Edges]));

    labelNetwork[0].forEach((node) => {
      node.label = labelMap.get(node.id) || null;
      node.size = nodeSizeMap.get(node.id) * 40 || 10;
      node.color = "gray";
      if (node.label) {
        node.color = { color: "blue", border: "darkblue" };
        node.font = {
          size: 14,
          color: "darkblue",
          face: "sans",
          background: "white",
        };
      }
    });

    return labelNetwork;
  }

  const exportToCsv = () => {
    const header = "Ranking,Name,Impact Score\r\n";
    const rows = input[1]
      .map((row, i) => `${i + 1},${row.label},${row.y}\r\n`)
      .join("");
    const CsvString = `data:application/csv,${encodeURIComponent(
      header + rows
    )}`;

    // Create a temporary link and trigger the download
    const link = document.createElement("a");
    link.href = CsvString;
    link.download = "change-agents.csv";
    document.body.appendChild(link);
    link.click();

    // Remove the link after the download is triggered
    document.body.removeChild(link);
  };
  let network = null;
  if (isLoading || isNetworkLoading) {
    return <Loader />;
  } else if (isSuccess & isNetworkSuccess) {
    getScatterData();
    network = labelNetworkData();
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  if (changeAgentsData && changeAgentsData.length === 0) return null;

  return (
    <>
      <div className="container py-3">
        <div className="col-sm-8">
          <div className="row">
            <div className="col text-center ">
              <label class="form-label" htmlFor="typeNumber">
                <strong>How many People do you want to select ? </strong>
              </label>
            </div>
            <div className="col-sm-2 ">
              <input
                min="1"
                max={changeAgentsData.length / 2}
                type="number"
                id="typeNumber"
                class="form-control"
                value={selectedPeople}
                onChange={(e) => {
                  setSelectedPeople(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-4 ">
              <button className="btn btn-primary " onClick={exportToCsv}>
                Download
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-4  pt 2"></div>

      <div className="container">
        <div className="row">
          <DataTable
            columns={COLUMNS}
            data={input[1]}
            full_width={false}
            error={isError}
            loading={isLoading}
          />
          {networkData && (
            <NetworkGraphBasic nodesData={network[0]} edgesData={network[1]} />
          )}
        </div>
      </div>
    </>
  );
};

export default ChangeAgents;
