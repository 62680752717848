import React, { useEffect, useRef } from "react";
import { Network, DataSet } from "vis-network/standalone/esm/vis-network";

const NetworkGraphBasic = ({ nodesData, edgesData }) => {
  const graphRef = useRef(null);
  const networkInstance = useRef(null);
  const nodes = useRef(new DataSet());
  const edges = useRef(new DataSet());
  // Debounced update flag
  const updateTimeout = useRef(null);

  // base network configuration
  let options = {
    nodes: {
      shape: "dot",
      fixed: {
        x: false,
        y: false,
      },
      size: 10,
    },
    edges: {
      arrows: {
        to: {
          enabled: true,
          scaleFactor: 0.5,
          type: "arrow",
        },
      },
      smooth: false,
      width: 1.5,
      color: {
        opacity: 0.5,
      },
    },
    physics: false,
  };

  useEffect(() => {
    if (!graphRef.current) return;

    // Initialize the network once
    if (!networkInstance.current) {
      nodes.current = new DataSet(nodesData);
      edges.current = new DataSet(edgesData);

      networkInstance.current = new Network(
        graphRef.current,
        { nodes: nodes.current, edges: edges.current },
        options
      );
    }

    return () => {
      if (networkInstance.current) {
        networkInstance.current.destroy();
        networkInstance.current = null;
      }
    };
  }, [nodesData, edgesData]); // Run only on mount

  useEffect(() => {
    if (!networkInstance.current) return;

    // timeout function fo give the graph just a few ms to redraw the update for nodes and edges
    if (updateTimeout.current) clearTimeout(updateTimeout.current);

    updateTimeout.current = setTimeout(() => {
      const currentView = networkInstance.current.getViewPosition();
      const currentScale = networkInstance.current.getScale();

      nodes.current.update(nodesData);
      edges.current.update(edgesData);

      // Restore zoom and position
      networkInstance.current.moveTo({
        position: currentView,
        scale: currentScale,
        animation: false,
      });
    }, 100); // 100ms debounce
  }, [nodesData, edgesData]);

  return (
    <>
      <div className="col-lg-5 mt-4 box-network">
        <div
          ref={graphRef}
          className="row mt-3 justify-content-md-center "
          style={{ height: 600 }}
        />
      </div>
    </>
  );
};

export default NetworkGraphBasic;
